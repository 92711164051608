import React from "react"
import { Link } from "gatsby"

const BlogPreview = props => {
  const { slug, image, date, title, excerpt, readTime } = props.previewData
  return (
    <Link to={`${slug}`}>
      <div className="preview-box">
        <div className="bottom">
          <section>
            <p className="preview-text preview-date text">{date}</p>
            <p className="preview-text preview-title header">{title}</p>
            <p className="preview-text text">{excerpt}</p>
            <p className="read-time-text text">{readTime} min read</p>
          </section>
        </div>
      </div>
    </Link>
  )
}

export default BlogPreview
