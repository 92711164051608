import React from "react"
import { Layout} from "../components/Layout"
import BlogList from "../components/BlogList"
const Blog = () => {
  return (
    <Layout>
      <div className="blog-container">
        <section className="blog-preview-container">
        <BlogList/>
        </section>
      </div>
    </Layout>
  )
}
export default Blog
